import {
  SIGN_IN,
  SIGN_OUT,
  SignInAction,
  SignOutAction,
} from 'state/types/auth';

// Sign in action creator
export const signIn = (token: string): SignInAction => ({
  type: SIGN_IN,
  payload: {
    token,
  },
});

// Sign out action creator
export const signOut = (): SignOutAction => ({
  type: SIGN_OUT,
});
