export const SIGN_IN = 'SIGN_IN';
export const SIGN_OUT = 'SIGN_OUT';

export interface AuthState {
  signedIn: boolean;
  accessToken?: string;
}

export interface SignInAction {
  type: typeof SIGN_IN;
  payload: {
    token: string;
  };
}

export interface SignOutAction {
  type: typeof SIGN_OUT;
  payload?: {};
}

export type AuthActionTypes = SignInAction | SignOutAction;
