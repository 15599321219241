import React from 'react';
import styled from 'styled-components';
import { Box } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { isMobileSelector } from 'state/actions/ui';

const StyledHeader = styled(Box)`
  display: flex;
  padding: 8px 8px 16px 8px;
  flex-direction: ${props => (props.className === 'sm' ? 'column' : 'row')};
  justify-content: center;
  justify-items: center;

  button {
    height: 38px;
  }

  &.sm {
    margin-top: 48px;
    button {
      margin: 6px 0;
    }
  }
`;

interface PageHeaderProps {
  children: React.ReactNode;
}
export const PageHeader = ({ children }: PageHeaderProps) => {
  const isMobile = useSelector(isMobileSelector);

  return (
    <StyledHeader className={isMobile ? 'sm' : ''}>{children}</StyledHeader>
  );
};
