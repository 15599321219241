import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { AuthState } from 'state/types/auth';
import { AppState } from 'state/store';
import { connect } from 'react-redux';

export interface ProtectedRouteProps extends RouteProps {
  auth: AuthState;
}

const ProtectedRouteComponent = ({
  auth,
  children,
  ...rest
}: ProtectedRouteProps) => (
  <Route
    {...rest}
    render={() => (auth.signedIn ? children : <Redirect to="/sign-in" />)}
  />
);

const mapStateToProps = (state: AppState) => {
  return state;
};

export const ProtectedRoute = connect(mapStateToProps)(ProtectedRouteComponent);
