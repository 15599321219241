import React from 'react';

export const Logo = (props: React.HTMLProps<HTMLDivElement>) => (
  <div {...props}>
    <svg
      className="logo"
      xmlns="http://www.w3.org/2000/svg"
      enableBackground="new 0 0 177 32.3"
      viewBox="0 0 177 32.3"
      y="0px"
      x="0px"
      version="1.1"
    >
      <path
        id="path"
        d="M24.1,16.2c0,2.7-1,5.4-2.5,6.8c-1.3,1.2-3.5,1.9-7.3,1.9h-4.1c-0.1,0-0.2-0.1-0.2-0.2V7.9
            c0-0.1,0.1-0.2,0.2-0.2h4.4c2.1,0,4.7,0.2,6.7,1.8C22.9,10.8,24.1,13.2,24.1,16.2
            M32.6,23.8c1-2.3,1.4-4.9,1.4-7.4
            c0-4.8-1.5-7.6-2.2-8.8C27.5-0.2,18.8,0,15.6,0H5c-3.3,0-5,3-5,5.9v0.2v20.1v0.2c0,2.9,1.7,5.9,5,5.9l12.2,0c3,0,6.1-0.2,9.5-2.1
            C30.3,28.3,31.8,25.7,32.6,23.8
            M55.5,19.5c0,0.1-0.1,0.2-0.2,0.2h-5.8c-0.2,0-0.3-0.2-0.2-0.3L55,8.1c0.1-0.2,0.4-0.1,0.4,0.1
            V19.5z M79.7,26.2L79.7,26.2l0-0.5c0-0.3-0.2-0.5-0.5-0.5H64.1c-0.1,0-0.2-0.1-0.2-0.2v-5c0-0.1,0.1-0.2,0.2-0.2h9.1
            c0.7,0,1.3-0.6,1.3-1.2v-4.5c0-0.7-0.6-1.2-1.3-1.2h-9.1c-0.1,0-0.2-0.1-0.2-0.2V7.4c0-0.1,0.1-0.2,0.2-0.2h14.8
            c0.3,0,0.5-0.2,0.5-0.5V4.9c0-3.2-3.1-4.9-6-4.9H53.7c-2.2,0-4.5,1-5.5,2.8h0L32.7,31.6c-0.2,0.3,0.1,0.7,0.5,0.7h6.2v0
            c2.2,0,4.2-1.6,5.2-3.6l1.1-2.2c0-0.1,0.1-0.1,0.2-0.1h9.7V27v0.3c0,3.3,3.1,5,6,5h13.1c3.3,0,5-3.1,5-5.9V26.2z M125.9,19.7h-6.5
            c-0.1,0-0.3-0.1-0.2-0.3l3.2-9.8c0.1-0.2,0.4-0.2,0.4,0l3.3,9.8C126.1,19.5,126,19.7,125.9,19.7 M141,31.6L129.1,3.2h0
            c-0.9-2.1-3.3-3.2-5.7-3.2h-1.6c-2.4,0-4.8,1.1-5.7,3.2L107,25H96.3c-1,0-2.1-0.6-2.1-1.8V4.9c0-3.2-3.1-4.9-6-4.9h-3.4
            c-0.3,0-0.5,0.2-0.5,0.5l0,26.8c0,3.3,3.1,5,6,5h17c0,0,0,0,0,0h3.9c2.6,0.1,4.5-2,5.3-4.4l0.4-1.4c0-0.1,0.1-0.1,0.2-0.1h11.2
            c0.1,0,0.2,0.1,0.2,0.1l0.5,1.4c0.8,2.4,2.7,4.5,5.3,4.4h6.3C140.9,32.3,141.2,31.9,141,31.6 M177,31.8v-27c0-2.5-2.6-4.8-5.4-4.8
            h-4c-0.3,0-0.5,0.2-0.5,0.5V17c0,0.2-0.3,0.3-0.4,0.1l-7-11c-2.7-4.4-6.4-6.2-12.1-6.2h-4c-0.3,0-0.5,0.2-0.5,0.5v26.8
            c0,3.3,3.1,5,6,5h3.4v0c0.3,0,0.5-0.2,0.5-0.5V14.4c0-0.2,0.3-0.3,0.4-0.1l7.8,11.7c3.5,5.2,7.1,6.3,11.5,6.3h3.9
            C176.8,32.3,177,32.1,177,31.8"
      />
    </svg>
  </div>
);
