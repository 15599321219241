import { UserState, UserActionTypes, USER_INFO } from 'state/types/user';

const initialState: UserState = {};

export const userReducer = (
  state = initialState,
  action: UserActionTypes
): UserState => {
  switch (action.type) {
    case USER_INFO: {
      return {
        ...state,
        name: action.payload.name,
        email: action.payload.email,
        userId: action.payload.userId,
      };
    }
    default:
      return state;
  }
};
