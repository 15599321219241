import { createMuiTheme } from '@material-ui/core/styles';
import { isIS } from '@material-ui/core/locale';

export const theme = createMuiTheme(
  {
    palette: {
      primary: {
        main: '#eb2232',
      },
      secondary: {
        main: '#0091ea',
      },
    },
    overrides: {
      MuiAvatar: {
        colorDefault: {
          backgroundColor: '#eb2232',
        },
      },
      MuiFormHelperText: {
        root: {
          position: 'absolute',
          bottom: '-20px',
          right: '0',
          letterSpacing: 'inherit',
          overflow: 'hidden',
          maxWidth: '100%',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
        },
      },
    },
  },
  isIS
);
