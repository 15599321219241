/* eslint-disable react/style-prop-object */
import React from 'react';
import styled from 'styled-components';
import { Box, Grid, Typography, Divider, Button } from '@material-ui/core';
import { Invoice as InvoiceModel } from 'api/models';
import { InvoiceService } from 'api/services';
import { Table } from 'components/Table';
import { FormattedNumber, FormattedDate, FormattedMessage } from 'react-intl';
import { ColumnMap } from 'components/Table/types';
import download from "downloadjs";
import { PictureAsPdf } from '@material-ui/icons';

const InvoiceContainer = styled(Box)`
  min-width: 360px;
  overflow: hidden;
  .spinner {
    margin: auto;
  }
  .invoice-items {
    margin: 16px 0;
  }
  .MuiTableContainer-root {
    box-shadow: 0;
  }
`;

const InvoiceHeader = styled(Grid)`
  padding: 16px 0 8px 0;
  .invoice-title {
    font-size: 28px;
    font-weight: 600;
    color: #eb6c70;
    margin-bottom: -8px;
  }
  .invoice-subtitle {
    font-size: 16px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.54);
  }
`;

const InvoiceCustomer = styled(Box)`
  font-size: 20px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.76);
  margin-bottom: 12px;
`;

const InvoiceAmount = styled(Grid)`
  padding: 12px;
`;

const InvoiceStatus = styled(Box)`
  display: flex;
  align-content: flex-end;
  justify-content: flex-start;
  padding: 12px;
  color: rgba(0, 0, 0, 0.76);
  margin: 12px 0;
`;

export interface InvoiceProps {
  id: number;
  setLoading: (loading: boolean) => void;
  loading: boolean;
}

export const Invoice = (props: InvoiceProps) => {
  const [invoice, setInvoice] = React.useState<InvoiceModel>(
    {} as InvoiceModel
  );
  const setLoading = props.setLoading;

  React.useEffect(() => {
    setLoading(true);
    InvoiceService.getInvoice(props.id).then(res => {
      setInvoice(res.data);
      setLoading(false);
    });
  }, [props.id, setLoading]);

  const invoiceColumns: ColumnMap = {
    name: {
      name: 'Vöruheiti',
    },
    quantity: {
      name: 'Magn',
      type: 'decimal',
    },
    unit_price: {
      name: 'Einingaverð',
      type: 'decimal',
      align: 'right',
    },
    discount: {
      name: 'Afsláttur',
      type: 'integer',
      align: 'right',
    },
    price: {
      name: 'Upphæð',
      type: 'integer',
      align: 'right',
    },
  };

  const getInvoicePdf = (invoice: InvoiceModel): void => {
    InvoiceService.getInvoicePdf(invoice.id)
      .then(res => download(res.data, `invoice ${invoice.id} - ${invoice.customer_name}.pdf`, res.headers['content-type']))
  };

  return (
    <InvoiceContainer>
      {!props.loading && (
        <Grid
          container
          direction="column"
          justify="space-evenly"
          alignItems="stretch"
        >
          <InvoiceHeader
            item
            direction="column"
            justify="space-evenly"
            alignItems="stretch"
          >
            <Typography className="invoice-title">
              <FormattedMessage
                id="invoice.title"
                defaultMessage="Invoice no. S{invoiceNumber}"
                values={{ invoiceNumber: invoice.id }}
              />
            </Typography>
            <Typography className="invoice-subtitle">
              <Typography variant="subtitle1">
                <FormattedDate value={invoice.date} />
              </Typography>
            </Typography>
          </InvoiceHeader>

          <InvoiceCustomer>{invoice.customer_name}</InvoiceCustomer>

          <Divider light />

          {invoice.items && (
            <Table
              className="invoice-items"
              columns={invoiceColumns}
              data={invoice.items}
              size="small"
              containerElement="div"
            />
          )}

          <InvoiceAmount>
            <Box>
              Upphæð án vsk.:{' '}
              <FormattedNumber
                value={invoice.amount}
                style="currency"
                currency="ISK"
              />
            </Box>
            <Box>
              Vsk. upphæð:{' '}
              <FormattedNumber
                value={invoice.tax_amount}
                style="currency"
                currency="ISK"
              />
            </Box>
            <Box>
              <b>
                Samtals:{' '}
                <FormattedNumber
                  value={invoice.amount}
                  style="currency"
                  currency="ISK"
                />
              </b>
            </Box>
          </InvoiceAmount>

          <Button
            color="secondary"
            startIcon={<PictureAsPdf />}
            onClick={() => getInvoicePdf(invoice)}
          >
            Sækja PDF
          </Button>

          {invoice.sent_to_dk && (
            <InvoiceStatus>
              Reikningsfært <FormattedDate value={invoice.sent_to_dk} />
            </InvoiceStatus>
          )}
        </Grid>
      )}
    </InvoiceContainer>
  );
};
