export const USER_INFO = 'USER_INFO';

export interface UserInfo {
  userId?: number;
  name?: string;
  email?: string;
}

export interface UserState extends UserInfo {}

export interface UserInfoAction {
  type: typeof USER_INFO;
  payload: UserInfo;
}

export type UserActionTypes = UserInfoAction;
