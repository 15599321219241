import styled from 'styled-components';
import React from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import { AppBar, Avatar, Menu, MenuItem, IconButton } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { userInfoSelector } from 'state/actions/user';
import { Menu as MenuIcon } from '@material-ui/icons';
import { isMobileSelector } from 'state/actions/ui';

const TopNavContainer = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  padding-bottom: 18px;
  z-index: 1201;
  .icon {
    color: #fff;
  }
`;

const UnstyledButton = styled.button`
  border: none;
  padding: 0;
  background: none;
  color: inherit;
  font: inherit;
  cursor: pointer;
  outline: none;
`;

const Spacer = styled.div`
  display: flex;
  flex: 1 1 100%;
`;

interface TopNavProps {
  signOutCallback: () => void;
  menuCallback: () => void;
}
export const TopNav = (props: TopNavProps) => {
  const [anchor, setAnchor] = React.useState<null | HTMLElement>(null);
  const userInfo = useSelector(userInfoSelector);
  const isMobile = useSelector(isMobileSelector);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchor(event.currentTarget);
  };

  const handleClose = () => {
    setAnchor(null);
  };

  const getUserAvatar = (userName?: string): string | undefined => {
    if (!userName) {
      return undefined;
    }
    return userName[0].toUpperCase();
  };

  const navItems = (
    <React.Fragment>
      <UnstyledButton onClick={handleClick}>
        <Avatar>{getUserAvatar(userInfo.name)}</Avatar>
      </UnstyledButton>
      <Menu
        id="user-menu"
        anchorEl={anchor}
        open={Boolean(anchor)}
        onClose={handleClose}
        keepMounted
      >
        <MenuItem disabled divider>
          {userInfo.email}
        </MenuItem>
        <MenuItem onClick={props.signOutCallback}>
          <FormattedMessage id="nav.sign-out" defaultMessage="Sign out" />
        </MenuItem>
      </Menu>
    </React.Fragment>
  );

  const wrap = () => {
    if (isMobile) {
      return (
        <AppBar>
          <Toolbar>
            <IconButton onClick={props.menuCallback}>
              <MenuIcon className="icon" color="action" />
            </IconButton>
            <Spacer />
            {navItems}
          </Toolbar>
        </AppBar>
      );
    }
    return <Toolbar>{navItems}</Toolbar>;
  };

  return <TopNavContainer>{wrap()}</TopNavContainer>;
};
