import React from 'react';
import { Invoices, Transactions } from 'pages';
import { Switch, Route } from 'react-router-dom';

export const Router = () => (
  <Switch>
    <Route exact path="/">
      <Transactions />
    </Route>

    <Route path="/transactions">
      <Transactions />
    </Route>

    <Route path="/invoices">
      <Invoices />
    </Route>
  </Switch>
);
