import React from 'react';
import {
  TableContainer,
  Paper,
  Table as MaterialTable,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  LinearProgress,
} from '@material-ui/core';
import styled from 'styled-components';

import { ColumnMap, DataType, TableProps } from 'components/Table/types';
import { useSelector } from 'react-redux';
import { isMobileSelector } from 'state/actions/ui';

const Container = styled.div`
  width: 100%;
  .MuiTableRow-hover {
    cursor: ${props => (props.className === 'clickable' ? 'pointer' : 'auto')};
  }
  .MuiTableCell-root {
    &.hidden {
      display: none;
    }
  }
`;

export const Table = ({
  columns,
  data,
  children,
  loading,
  onRowClick,
  containerElement,
  ...rest
}: TableProps) => {
  return (
    <Container className={onRowClick ? 'clickable' : ''}>
      <TableContainer component={containerElement ?? Paper}>
        <MaterialTable {...rest}>
          <TableHead>
            <TableHeader columns={columns} />
          </TableHead>
          <TableBody>
            <TableData columns={columns} data={data} onRowClick={onRowClick} />
          </TableBody>
        </MaterialTable>
        {loading && <LinearProgress />}
        {children}
      </TableContainer>
    </Container>
  );
};

const TableHeader = (props: { columns: ColumnMap }) => {
  const isMobile = useSelector(isMobileSelector);

  return (
    <TableRow>
      {Object.keys(props.columns).map(key => (
        <TableCell
          key={key}
          align={props.columns[key].align}
          className={
            props.columns[key].hideOnMobile && isMobile ? 'hidden' : ''
          }
        >
          {props.columns[key].name}
        </TableCell>
      ))}
    </TableRow>
  );
};

const TableData = ({ columns, data, onRowClick }: TableProps) => {
  const isMobile = useSelector(isMobileSelector);

  const formatData = (field: any, type: DataType): string => {
    if (!field) {
      return '';
    }
    switch (type) {
      case 'integer':
        return new Intl.NumberFormat('is-IS', {
          maximumFractionDigits: 0,
        }).format(field);
      case 'decimal':
        return new Intl.NumberFormat('is-IS', {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        }).format(field);
      case 'currency':
        // Probably a good idea to have this currency agnostic
        return (
          new Intl.NumberFormat('is-IS', {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          }).format(field) + ' kr'
        );
      case 'boolean':
        return field ? 'true' : 'false';
      case 'string':
        return field;
      case 'date':
        return new Intl.DateTimeFormat('is-IS').format(new Date(field));
      case 'datetime':
        return new Intl.DateTimeFormat('is-IS', {
          hour12: false,
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          second: 'numeric',
        }).format(new Date(field));
      default:
        return field;
    }
  };

  return (
    <React.Fragment>
      {data.map((dataRow, index) => (
        <TableRow
          key={index}
          data-id={dataRow['id'] ?? undefined}
          onClick={onRowClick}
          hover={onRowClick ? true : false}
        >
          {Object.keys(columns).map(k => {
            return (
              <TableCell
                key={k}
                align={columns[k].align}
                className={columns[k].hideOnMobile && isMobile ? 'hidden' : ''}
              >
                {columns[k].type
                  ? formatData(dataRow[k], columns[k].type ?? 'string')
                  : dataRow[k]}
              </TableCell>
            );
          })}
        </TableRow>
      ))}
    </React.Fragment>
  );
};
