import { http } from 'api/core/http';
import { RequestOptions } from 'api/core/RequestOptions';
import { LocationCollection, Location } from 'api/models';

export class LocationService {
  /**
   * Get a collection of locations
   * @param params Request options
   */
  public static getLocations = (params?: RequestOptions) => {
    return http.client.get<LocationCollection>(`location`, {
      params,
    });
  };

  /**
   * Get a single location
   * @param locationId ID of the location
   * @param params Request options
   */
  public static getLocation = (locationId: number, params?: RequestOptions) => {
    return http.client.get<Location>(`location/${locationId}`, {
      params,
    });
  };
}
