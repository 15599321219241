import React from 'react';
import styled from 'styled-components';

const StyledDiv = styled.div`
  width: 100%;
`;

interface ContainerProps {
  children: React.ReactNode;
}
export const Container = ({ children }: ContainerProps) => {
  return <StyledDiv>{children}</StyledDiv>;
};
