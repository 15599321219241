import React from 'react';
import { TablePagination } from '@material-ui/core';
import { Table } from 'components/Table';
import { PaginatedTableProps } from 'components/Table/types';
import { useSelector } from 'react-redux';
import { isMobileSelector } from 'state/actions/ui';
import styled from 'styled-components';

const Container = styled.div`
  &.sm {
    .MuiTablePagination-root {
      display: flex;
    }
    .MuiTablePagination-toolbar {
      flex: 1 1 auto;
      flex-wrap: wrap;
    }
    .MuiTablePagination-caption {
      flex: 1 1 auto;
    }
    .MuiTablePagination-actions {
      margin-left: 0;
      flex: 1 1 auto;
      align-self: flex-end;
      display: flex;
      justify-content: flex-end;
    }
    /* Hide the count because it wraps at mobile resolutions */
    p.MuiTypography-root:nth-child(4) {
      display: none;
    }
  }
`;

export const PaginatedTable = (props: PaginatedTableProps) => {
  const isMobile = useSelector(isMobileSelector);

  return (
    <Table
      columns={props.columns}
      data={props.data}
      loading={props.loading}
      onRowClick={props.onRowClick}
    >
      <Container className={isMobile ? 'sm' : ''}>
        <TablePagination
          rowsPerPageOptions={[15, 25, 50, 100]}
          component="div"
          count={props.count}
          rowsPerPage={props.rowsPerPage ?? 25}
          page={props.page ?? 1}
          onChangePage={props.onPaginate}
          onChangeRowsPerPage={props.onPerPage}
        />
      </Container>
    </Table>
  );
};
