export const SHOW_SNACKBAR = 'SHOW_SNACKBAR';
export const HIDE_SNACKBAR = 'HIDE_SNACKBAR';

export type SnackbarVariant = 'info' | 'success' | 'error';

export interface SnackbarOptions {
  duration?: number;
  variant?: SnackbarVariant;
}

export interface SnackbarInfo {
  message: string;
  options: SnackbarOptions;
}

export interface SnackbarState {
  open: boolean;
  message?: string;
  duration?: number;
  variant?: SnackbarVariant;
}

export interface ShowSnackbar {
  type: typeof SHOW_SNACKBAR;
  payload: SnackbarInfo;
}

export interface HideSnackbar {
  type: typeof HIDE_SNACKBAR;
}

export type SnackbarActionTypes = ShowSnackbar | HideSnackbar;
