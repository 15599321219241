import React from 'react';
import dayjs from 'dayjs';
import is from 'dayjs/locale/is';
import ReactDOM from 'react-dom';
import DayjsUtils from '@date-io/dayjs';
import { Provider } from 'react-redux';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import { IntlProvider } from 'react-intl';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import * as Sentry from '@sentry/react';
import { omit } from 'lodash';

import { SignIn } from 'pages/SignIn';
import { App } from 'components/App';
import { Navigation } from 'components/Navigation';
import { ProtectedRoute } from 'components/Router';
import { store } from 'state/store';
import { theme } from 'util/theme';
import 'index.css';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.NODE_ENV,
  normalizeDepth: 0,
  enabled: process.env.NODE_ENV !== 'development',
});

const setSentryContext = () => {
  const state = omit(store.getState(), 'auth');
  Sentry.setUser({
    id: String(state.user.userId),
    name: state.user.name,
    email: state.user.email,
  });

  Sentry.setExtra('appState', state);
};

// Update context with state changes
store.subscribe(setSentryContext);

const locale = 'is';
dayjs.locale(is);

const Root = () => {
  const [messages] = React.useState(require(`lang/${locale}.json`));

  return (
    <React.StrictMode>
      <Provider store={store}>
        <IntlProvider locale={locale} messages={messages}>
          <ThemeProvider theme={theme}>
            <MuiPickersUtilsProvider utils={DayjsUtils} locale={locale}>
              <CssBaseline />
              <BrowserRouter>
                <Switch>
                  <Route path="/sign-in" component={SignIn} />

                  <ProtectedRoute path="/">
                    <Navigation>
                      <App />
                    </Navigation>
                  </ProtectedRoute>

                  <Route path="*">
                    <h1>404</h1>
                  </Route>
                </Switch>
              </BrowserRouter>
            </MuiPickersUtilsProvider>
          </ThemeProvider>
        </IntlProvider>
      </Provider>
    </React.StrictMode>
  );
};

ReactDOM.render(<Root />, document.getElementById('root'));
