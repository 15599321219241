import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Container, Box, Paper, Typography, Divider } from '@material-ui/core';
import { useIntl } from 'react-intl';

import { Gradient } from 'components/Gradient';
import { SignInForm } from 'components/SignInForm';

import { AppState } from 'state/store';
import { signIn } from 'state/actions/auth';
import { FormError } from 'types/error';

import { AuthenticationService } from 'api/services';
import { setToken } from 'api/core/token';
import { Logo } from 'components/Logo';
import { theme } from 'util/theme';

const BoxContainer = styled(Box)`
  padding: 0 100px;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  justify-items: center;
  justify-content: center;
  position: relative;

  .divider {
    margin: 8px 0;
  }

  @media (max-width: 600px) {
    padding: 0 16px;
  }
`;

const StyledLogo = styled(Logo)`
  position: absolute;
  right: 20px;
  top: 20px;
  width: 150px;
  svg {
    fill: ${theme.palette.primary.light};
  }

  @media (max-width: 600px) {
    top: 30px;
    left: 50%;
    transform: translateX(-50%);
    width: 200px;
  }
`;

interface SignInProps extends RouteComponentProps {
  signedIn: boolean;
  accessToken?: string;
  signIn: typeof signIn;
}

const SignInPage = (props: SignInProps) => {
  const [signingIn, setSigningIn] = React.useState(false);
  const [error, setError] = React.useState<FormError>();
  const intl = useIntl();

  React.useEffect(() => {
    if (props.signedIn) {
      props.history.replace('/');
    }
  }, [props.history, props.signedIn]);

  const signIn = (email: string, password: string) => {
    setSigningIn(() => true);
    setError({});

    AuthenticationService.login({ email, password })
      .then(res => {
        setSigningIn(() => false);
        setToken(res.data.token);
        props.signIn(res.data.token);
        props.history.push('/');
      })
      .catch(err => {
        setSigningIn(() => false);
        if (err.response.status === 422) {
          setError({
            password: intl.formatMessage({
              id: 'errors.incorrect-login',
              defaultMessage: 'Incorrect email or password',
            }),
          });
        }
      });
  };
  return (
    <Gradient container justify="center" alignItems="center">
      <Container maxWidth="sm">
        <Paper>
          <BoxContainer>
            <StyledLogo className="logo" />
            <Typography variant="h5">
              {intl.formatMessage({
                id: 'sign-in.title',
                defaultMessage: 'Sign in',
              })}
            </Typography>
            <Divider className="divider" />
            <SignInForm
              handleSubmit={signIn}
              signingIn={signingIn}
              error={error}
            />
          </BoxContainer>
        </Paper>
      </Container>
    </Gradient>
  );
};

const mapStateToProps = (state: AppState) => {
  return { ...state.auth };
};

export const SignIn = connect(mapStateToProps, { signIn })(SignInPage);
