import { http } from 'api/core/http';
import { RequestOptions } from 'api/core/RequestOptions';
import { UserCollection, User } from 'api/models';

export class UserService {
  /**
   * Get a collection of users
   * @param params Request options
   */
  public static getUsers = (params?: RequestOptions) => {
    return http.client.get<UserCollection>(`user`, {
      params,
    });
  };

  /**
   * Get a single user
   * @param userId ID of the user
   * @param params Request options
   */
  public static getUser = (userId: number, params?: RequestOptions) => {
    return http.client.get<User>(`user/${userId}`, {
      params,
    });
  };

  /**
   * Get info about the current user
   */
  public static whoami = () => {
    return http.client.get<User>(`whoami`);
  };
}
