import React from 'react';
import dayjs from 'dayjs';
import styled from 'styled-components';
import { AddRounded } from '@material-ui/icons';
import { Dialog } from 'components/common';
import { DialogForm } from 'components/common/DialogForm';
import { Drop } from 'components/Transaction';
import { FormattedMessage, useIntl } from 'react-intl';
import { InvoiceService } from 'api/services';
import { PageHeader } from 'components/common/PageHeader';
import { TransactionTable } from 'components/Transaction/TransactionTable';
import { Typography, Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { snack } from 'util/snack';

const Header = styled(Typography)`
  display: flex;
  flex: 1 1 auto;
`;

const Spacer = styled.div`
  padding: 0 4px;
`;

export const Transactions = () => {
  const intl = useIntl();
  const history = useHistory();

  const [dropDialogOpen, setDropDialogOpen] = React.useState(false);
  const [transactionPage, setTransactionPage] = React.useState(0);

  const [invoiceCreateDialogOpen, setInvoiceCreateDialogOpen] = React.useState(
    false
  );

  const [invoiceCreateSubmitting, setInvoiceCreateSubmitting] = React.useState(
    false
  );

  const closeCallback = (fetchData?: boolean) => {
    setDropDialogOpen(false);

    if (fetchData) {
      /**
       * Set the page twice to get around the change detection
       * React.useEffect doesn't trigger if the value doesn't change
       * Since the pagination is debounced anyways, this will only fire one API call
       */
      setTransactionPage(1);
      setTransactionPage(0);
    }
  };

  const onInvoiceCreate = (date: dayjs.Dayjs) => {
    setInvoiceCreateSubmitting(true);

    InvoiceService.createInvoices(date)
      .then(() => {
        setInvoiceCreateSubmitting(false);
        setInvoiceCreateDialogOpen(false);
        snack(
          intl.formatMessage({
            id: 'invoices.create-success',
            defaultMessage: 'Invoices created successfully',
          })
        );
        history.push('/invoices');
      })
      .catch(() => {
        setInvoiceCreateSubmitting(false);
        snack(
          intl.formatMessage({
            id: 'invoices.create-error',
            defaultMessage: 'Failed to create invoices',
          }),
          {
            variant: 'error',
            duration: 10000,
          }
        );
      });
  };

  return (
    <React.Fragment>
      <PageHeader>
        <Header variant="h4">
          {intl.formatMessage({
            id: 'transactions.title',
            defaultMessage: 'Transactions',
          })}
        </Header>
        <Button
          size="small"
          variant="contained"
          disableElevation
          color="primary"
          startIcon={<AddRounded />}
          onClick={() => setDropDialogOpen(true)}
        >
          <FormattedMessage
            id="transactions.upload-transactions"
            defaultMessage="Upload transactions"
          />
        </Button>
        <Spacer />
        <Button
          size="small"
          variant="contained"
          color="secondary"
          startIcon={<AddRounded />}
          onClick={() => setInvoiceCreateDialogOpen(true)}
          disableElevation
        >
          <FormattedMessage
            id="invoices.create-invoices"
            defaultMessage="Create invoices"
          />
        </Button>
      </PageHeader>
      <TransactionTable page={transactionPage} setPage={setTransactionPage} />
      <Drop open={dropDialogOpen} setClose={closeCallback} />
      <Dialog
        title={intl.formatMessage({
          id: 'invoices.create-invoices',
          defaultMessage: 'Create invoices',
        })}
        open={invoiceCreateDialogOpen}
        setClose={() => setInvoiceCreateDialogOpen(false)}
      >
        <DialogForm
          onSubmit={onInvoiceCreate}
          submitting={invoiceCreateSubmitting}
        />
      </Dialog>
    </React.Fragment>
  );
};
