import { http } from 'api/core/http';
import { InvoiceCollection, Invoice } from 'api/models';
import { RequestOptions } from 'api/core/RequestOptions';
import dayjs from 'dayjs';

export class InvoiceService {
  /**
   * Get a invoice collection
   * @param params Request options
   */
  public static getInvoices = (params?: RequestOptions) => {
    return http.client.get<InvoiceCollection>(`invoice`, {
      params,
    });
  };

  /**
   * Get a single invoice
   * @param invoiceId ID of the invoice
   * @param params Request options
   */
  public static getInvoice = (invoiceId: number, params?: RequestOptions) => {
    return http.client.get<Invoice>(`invoice/${invoiceId}`, {
      params,
    });
  };

  /**
   * Create invoices from transactions for a given date
   * @param date Transaction date to generate invoices from
   */
  public static createInvoices = (date: dayjs.Dayjs) => {
    const dateFrom = date.startOf('month').format('YYYY-MM-DD');
    const dateTo = date.endOf('month').format('YYYY-MM-DD');

    return http.client.post(`transaction/invoice`, {
      start: dateFrom,
      end: dateTo,
    });
  };

  public static processInvoices = (date: dayjs.Dayjs) => {
    const dateFrom = date.startOf('month').format('YYYY-MM-DD');
    const dateTo = date.endOf('month').format('YYYY-MM-DD');

    return http.client.post(`invoice/process`, {
      start: dateFrom,
      end: dateTo,
    });
  };

  /** Get invoice PDF */
  public static getInvoicePdf = (invoiceId: number) => {
    return http.client.get(`invoice/pdf/${invoiceId}`, {
      responseType: 'blob'
    })
  };
}
