import { http } from 'api/core/http';
import { RequestOptions } from 'api/core/RequestOptions';
import { CustomerCollection, Customer } from 'api/models';

export class CustomerService {
  /**
   * Get a collection of customers
   * @param params Request options
   */
  public static getCustomers = (params?: RequestOptions) => {
    return http.client.get<CustomerCollection>(`customer`, {
      params,
    });
  };

  /**
   * Get a single customer
   * @param customerId ID of the customer
   * @param params Request options
   */
  public static getCustomer = (customerId: number, params?: RequestOptions) => {
    return http.client.get<Customer>(`customer/${customerId}`, {
      params,
    });
  };
}
