import { http } from 'api/core/http';
import { RequestOptions } from 'api/core/RequestOptions';
import { ProductCollection } from 'api/models';

export class ProductService {
  public static getProducts = (params?: RequestOptions) => {
    return http.client.get<ProductCollection>(`product`, {
      params,
    });
  };
}
