export const SET_MOBILE_VIEW = 'SET_MOBILE_VIEW';
export const SET_DESKTOP_VIEW = 'SET_DESKTOP_VIEW';

export interface UI {
  isMobile: boolean;
}

export interface UIState extends UI {}

export interface SetMobileView {
  type: typeof SET_MOBILE_VIEW;
}

export interface SetDesktopView {
  type: typeof SET_DESKTOP_VIEW;
}

export type UIViewActionTypes = SetMobileView | SetDesktopView;
