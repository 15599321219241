import { Grid } from '@material-ui/core';
import styled from 'styled-components';

export const Gradient = styled(Grid)`
  height: 100%;
  width: 100%;
  flex: 1;
  background-image: linear-gradient(135deg, #ffaa85 10%, #b3315f 100%);
  overflow: hidden;
  position: static;
`;
