import React from 'react';
import dayjs from 'dayjs';
import styled from 'styled-components';
import { AddRounded } from '@material-ui/icons';
import { Dialog } from 'components/common';
import { DialogForm } from 'components/common/DialogForm';
import { FormattedMessage, useIntl } from 'react-intl';
import { InvoiceService } from 'api/services';
import { InvoiceTable } from 'components/Invoice/InvoiceTable';
import { PageHeader } from 'components/common/PageHeader';
import { Typography, Button } from '@material-ui/core';
import { snack } from 'util/snack';

const Header = styled(Typography)`
  display: flex;
  flex: 1 1 auto;
`;

export const Invoices = () => {
  const intl = useIntl();

  const [invoicePage, setInvoicePage] = React.useState(0);
  const [invoiceDialogOpen, setInvoiceDialogOpen] = React.useState(false);
  const [invoiceSubmitting, setInvoiceSubmitting] = React.useState(false);

  const onInvoiceSubmit = (date: dayjs.Dayjs) => {
    setInvoiceSubmitting(true);

    InvoiceService.processInvoices(date)
      .then(() => {
        setInvoiceSubmitting(false);
        setInvoiceDialogOpen(false);
        snack(
          intl.formatMessage({
            id: 'invoices.process-success',
            defaultMessage: 'Invoices processed successfully',
          })
        );
        setInvoicePage(1);
        setInvoicePage(0);
      })
      .catch(() => {
        setInvoiceSubmitting(false);
        snack(
          intl.formatMessage({
            id: 'invoices.process-error',
            defaultMessage: 'Failed to process invoices',
          }),
          {
            variant: 'error',
            duration: 10000,
          }
        );
      });
  };

  return (
    <React.Fragment>
      <PageHeader>
        <Header variant="h4">
          {intl.formatMessage({
            id: 'invoices.title',
            defaultMessage: 'Invoices',
          })}
        </Header>
        <Button
          size="small"
          variant="contained"
          color="primary"
          startIcon={<AddRounded />}
          disableElevation
          onClick={() => setInvoiceDialogOpen(true)}
        >
          <FormattedMessage
            id="invoices.process-invoices"
            defaultMessage="Process invoices"
          />
        </Button>
      </PageHeader>
      <InvoiceTable page={invoicePage} setPage={setInvoicePage} />

      <Dialog
        title={intl.formatMessage({
          id: 'invoices.process-invoices',
          defaultMessage: 'Process invoices',
        })}
        open={invoiceDialogOpen}
        setClose={() => setInvoiceDialogOpen(false)}
      >
        <DialogForm onSubmit={onInvoiceSubmit} submitting={invoiceSubmitting} />
      </Dialog>
    </React.Fragment>
  );
};
