import {
  SHOW_SNACKBAR,
  HIDE_SNACKBAR,
  SnackbarActionTypes,
  SnackbarOptions,
} from 'state/types/snackbar';

export const showSnackbar = (
  message: string,
  options?: SnackbarOptions
): SnackbarActionTypes => ({
  type: SHOW_SNACKBAR,
  payload: {
    message,
    options: options ?? {},
  },
});

export const hideSnackbar = (): SnackbarActionTypes => {
  return {
    type: HIDE_SNACKBAR,
  };
};
