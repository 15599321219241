import {
  SHOW_SNACKBAR,
  HIDE_SNACKBAR,
  SnackbarState,
  SnackbarActionTypes,
} from 'state/types/snackbar';

const initialState: SnackbarState = {
  open: false,
};

export const snackbarReducer = (
  state = initialState,
  action: SnackbarActionTypes
): SnackbarState => {
  switch (action.type) {
    case SHOW_SNACKBAR: {
      return {
        ...state,
        open: true,
        message: action.payload.message,
        duration: action.payload.options.duration,
        variant: action.payload.options.variant,
      };
    }
    case HIDE_SNACKBAR: {
      return {
        ...state,
        open: false,
      };
    }
    default:
      return state;
  }
};
