import {
  SET_MOBILE_VIEW,
  SET_DESKTOP_VIEW,
  UIViewActionTypes,
} from 'state/types/ui';
import { AppState } from 'state/store';

export const setMobileView = (): UIViewActionTypes => ({
  type: SET_MOBILE_VIEW,
});

export const setDesktopView = (): UIViewActionTypes => ({
  type: SET_DESKTOP_VIEW,
});

export const isMobileSelector = (state: AppState) => state.ui.isMobile;
