import React from 'react';
import { Button, TextField, Box, LinearProgress } from '@material-ui/core';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { ExitToApp } from '@material-ui/icons';
import { FormError } from 'types/error';

const FormContainer = styled(Box)`
  .margin {
    margin: 8px 0;
    &.extra {
      margin-top: 16px;
    }
  }
  .loader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
`;

export interface SignInFormProps {
  signingIn: boolean;
  handleSubmit: (email: string, password: string) => void;
  error?: FormError;
}

export const SignInForm = (props: SignInFormProps) => {
  const { register, handleSubmit, errors, setError } = useForm();
  const intl = useIntl();

  React.useEffect(() => {
    if (props.error) {
      for (const [name, message] of Object.entries(props.error)) {
        setError(name, {
          type: 'api-error',
          message,
        });
      }
    }
  }, [props.error, setError]);

  const onSubmit = handleSubmit(({ email, password }) => {
    props.handleSubmit(email, password);
  });

  return (
    <FormContainer>
      {props.signingIn && <LinearProgress className="loader" color="primary" />}
      <form onSubmit={onSubmit}>
        <TextField
          size="small"
          name="email"
          label={intl.formatMessage({
            id: 'form.email',
            defaultMessage: 'Email',
          })}
          className="margin"
          inputRef={register({
            required: intl.formatMessage({
              id: 'errors.required',
              defaultMessage: 'This field is required',
            }),
          })}
          error={Boolean(errors.email)}
          helperText={errors.email?.message}
          fullWidth
        />
        <TextField
          size="small"
          name="password"
          label={intl.formatMessage({
            id: 'form.password',
            defaultMessage: 'Password',
          })}
          type="password"
          className="margin"
          inputRef={register({
            required: intl.formatMessage({
              id: 'errors.required',
              defaultMessage: 'This field is required',
            }),
          })}
          error={Boolean(errors.password)}
          helperText={errors.password?.message}
          fullWidth
        />
        <Button
          variant="contained"
          color="primary"
          disableElevation
          className="extra margin"
          size="large"
          type="submit"
          fullWidth
          endIcon={<ExitToApp />}
          disabled={props.signingIn}
        >
          {intl.formatMessage({
            id: 'sign-in.sign-in',
            defaultMessage: 'Sign in',
          })}
        </Button>
      </form>
    </FormContainer>
  );
};
