import React from 'react';
import {
  Dialog as MaterialDialog,
  DialogTitle,
  Box,
  LinearProgress,
} from '@material-ui/core';
import styled from 'styled-components';

const DialogBody = styled(Box)`
  padding: 0 24px;
  min-height: 64px;
  min-width: 320px;
`;

const Loading = styled(LinearProgress)`
  margin-bottom: -4px;
`;

export interface DialogProps {
  title?: string;
  children: React.ReactNode;
  open: boolean;
  setClose: () => void;
  loading?: boolean;
}

export const Dialog = (props: DialogProps) => {
  return (
    <MaterialDialog onClose={props.setClose} open={props.open}>
      {props.loading && <Loading color="secondary" />}
      {props.title && <DialogTitle>{props.title}</DialogTitle>}

      <DialogBody>{props.children}</DialogBody>
    </MaterialDialog>
  );
};
