import React from 'react';
import { useDropzone } from 'react-dropzone';
import { Box, Typography, CircularProgress } from '@material-ui/core';
import { Dialog } from 'components/common';
import { LibraryAddRounded, CloseRounded } from '@material-ui/icons';
import styled from 'styled-components';
import { TransactionService } from 'api/services';
import { useIntl, FormattedMessage } from 'react-intl';
import { snack } from 'util/snack';

const Container = styled.div`
  display: flex;
  flex: 1;
  margin: 24px 0;
  min-width: 340px;
  min-height: 140px;
  align-content: center;
  align-items: center;

  justify-content: center;
  border-width: 2px;
  border-radius: 2px;
  border-style: dashed;
  border-color: '#bdbdbd';
  background: #fafafa;
  color: #bdbdbd;
  transition: border 0.24s ease-in-out;

  &:hover {
    border-color: #0091ea;
  }
  &.accepted {
    border-color: #0091ea;
  }
`;

const PaddedContainer = styled(Box)`
  padding-bottom: 24px;
  display: flex;
  justify-items: center;
  .icon {
    margin-right: 8px;
  }
`;

export interface DropProps {
  open: boolean;
  setClose: (fetchData?: boolean) => void;
}
export const Drop = (props: DropProps) => {
  const [uploaded, setUploaded] = React.useState(false);
  const [error, setError] = React.useState<string>();
  const intl = useIntl();
  const setClose = props.setClose;

  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    fileRejections,
  } = useDropzone({
    accept: '.csv',
    disabled: uploaded,
  });

  React.useEffect(() => {
    setError(undefined);

    if (acceptedFiles.length > 0) {
      setUploaded(true);
      const formData = new FormData();
      formData.append('file', acceptedFiles[0]);
      TransactionService.uploadTransactions(formData)
        .then(() => {
          // Clean everything up
          setUploaded(false);
          acceptedFiles.length = 0;
          snack(
            intl.formatMessage({
              id: 'transaction.upload-success',
              defaultMessage: 'Transaction file processed successfully',
            })
          );

          // Close the dialog and fetchData
          setClose(true);
        })
        .catch(() => {
          setError(
            intl.formatMessage({
              id: 'transaction.upload-error',
              defaultMessage: 'Failed to upload transaction file.',
            })
          );
          setUploaded(false);
        });
    }
  }, [acceptedFiles, intl, setClose]);

  React.useEffect(() => {
    if (fileRejections.length > 0) {
      setError(
        intl.formatMessage({
          id: 'transaction.upload-invalid-file',
          defaultMessage: 'Not a valid file.',
        })
      );
    }
  }, [fileRejections, intl]);

  return (
    <Dialog
      open={props.open}
      setClose={props.setClose}
      title={intl.formatMessage({
        id: 'transaction.upload-title',
        defaultMessage: 'File upload',
      })}
    >
      <Box>
        <Typography>
          <FormattedMessage
            id="transaction.upload-help"
            defaultMessage="Uploaded file must be LocSys formatted from WebFuel."
          />
        </Typography>
      </Box>

      <Container {...getRootProps()} className={uploaded ? 'accepted' : ''}>
        <input {...getInputProps()} disabled={uploaded} />
        {uploaded ? (
          <CircularProgress color="secondary" className="spinner" />
        ) : (
          <LibraryAddRounded fontSize="large" />
        )}
      </Container>

      {error && (
        <PaddedContainer>
          <CloseRounded color="primary" className="icon" />
          <Typography>{error}</Typography>
        </PaddedContainer>
      )}

      {uploaded && (
        <PaddedContainer>
          <Typography>
            <FormattedMessage
              id="transaction.upload-processing"
              defaultMessage="Processing transaction file... This may take a few moments"
            />
          </Typography>
        </PaddedContainer>
      )}
    </Dialog>
  );
};
