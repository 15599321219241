import {
  SET_MOBILE_VIEW,
  SET_DESKTOP_VIEW,
  UIState,
  UIViewActionTypes,
} from 'state/types/ui';

const initialState: UIState = {
  isMobile: false,
};

export const uiReducer = (
  state: UIState = initialState,
  action: UIViewActionTypes
): UIState => {
  switch (action.type) {
    case SET_MOBILE_VIEW: {
      return {
        ...state,
        isMobile: true,
      };
    }
    case SET_DESKTOP_VIEW: {
      return {
        ...state,
        isMobile: false,
      };
    }
    default: {
      return state;
    }
  }
};
