import React from 'react';
import { Button, Box } from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import { useForm, Controller } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { Loading } from 'components/common/Loading';

const FormContainer = styled(Box)`
  padding-bottom: 24px;
  form {
    .MuiFormControl-root {
      margin-bottom: 24px;
    }
  }
`;

interface DialogFormProps {
  onSubmit: (date: dayjs.Dayjs) => void;
  submitting: boolean;
}

export const DialogForm = (props: DialogFormProps) => {
  const intl = useIntl();

  const { handleSubmit, control } = useForm({
    defaultValues: {
      date: dayjs().subtract(1, 'month').startOf('month'),
    },
  });

  const onSubmit = handleSubmit(({ date }) => props.onSubmit(date));

  return (
    <FormContainer>
      <form onSubmit={onSubmit}>
        <Controller
          name="date"
          as={
            <DatePicker
              views={['year', 'month']}
              label={intl.formatMessage({
                id: 'form.month',
                defaultMessage: 'Month',
              })}
              openTo="month"
              format="MM.YYYY"
              // These props are required by the datepicker, even though the form is controlled...
              value={undefined}
              onChange={() => {}}
              fullWidth
            />
          }
          control={control}
        />
        <Button
          variant="contained"
          color="primary"
          disableElevation
          className="extra margin"
          size="large"
          type="submit"
          disabled={props.submitting}
          fullWidth
        >
          {props.submitting ? (
            <Loading indicatorColor="#fff" size={24} />
          ) : (
            <FormattedMessage id="form.submit" defaultMessage="Submit" />
          )}
        </Button>
      </form>
    </FormContainer>
  );
};
