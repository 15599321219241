import { http } from 'api/core/http';
import { TransactionCollection, Transaction } from 'api/models';
import { RequestOptions } from 'api/core/RequestOptions';

export class TransactionService {
  /**
   * Get a transaction collection
   * @param params Request options
   */
  public static getTransactions = (params?: RequestOptions) => {
    return http.client.get<TransactionCollection>(`transaction`, {
      params,
    });
  };

  /**
   * Get a single transaction
   * @param transactionId ID of the transaction
   * @param params Request options
   */
  public static getTransaction = (
    transactionId: number,
    params?: RequestOptions
  ) => {
    return http.client.get<Transaction>(`transaction/${transactionId}`, {
      params,
    });
  };

  /**
   * Upload a transaction file from WebFuel
   * @param transactionFile Transaction file
   */
  public static uploadTransactions = (transactionData: FormData) => {
    return http.client.post<any>(`transaction/upload`, transactionData);
  };
}
