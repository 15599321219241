import { AppState } from 'state/store';
import { USER_INFO, UserInfoAction, UserInfo } from 'state/types/user';

export const userInfo = (info: UserInfo): UserInfoAction => ({
  type: USER_INFO,
  payload: {
    ...info,
  },
});

export const userInfoSelector = (state: AppState) => state.user;
