import React from 'react';
import {
  Snackbar as MaterialSnack,
  IconButton,
  Slide,
  SlideProps,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'state/store';
import { hideSnackbar } from 'state/actions/snackbar';
import { CloseRounded } from '@material-ui/icons';
import { theme } from 'util/theme';
import styled from 'styled-components';
import { SnackbarVariant } from 'state/types/snackbar';

const getVariantColor = (variant?: SnackbarVariant): string => {
  switch (variant) {
    case 'info': {
      return theme.palette.primary.light;
    }
    case 'success': {
      return theme.palette.success.main;
    }
    case 'error': {
      return theme.palette.error.main;
    }
    default: {
      return theme.palette.background.paper;
    }
  }
};

const Snack = styled(MaterialSnack)`
  .MuiPaper-root {
    background: ${props => getVariantColor(props.className as SnackbarVariant)};
    color: ${props =>
      theme.palette.getContrastText(
        getVariantColor(props.className as SnackbarVariant)
      )};
  }
`;

const SlideLeft = (props: SlideProps) => <Slide {...props} direction="left" />;

export const Snackbar = () => {
  const state = useSelector((state: AppState) => state.snackbar);
  const dispatch = useDispatch();

  const handleClose = () => dispatch(hideSnackbar());

  return (
    <Snack
      className={state.variant}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={state.open}
      autoHideDuration={state.duration ?? 4000}
      key={state.message}
      onClose={handleClose}
      message={state.message}
      action={
        <IconButton onClick={handleClose} color="inherit" size="small">
          <CloseRounded />
        </IconButton>
      }
      TransitionComponent={SlideLeft}
    />
  );
};
