import { http } from 'api/core/http';
import { LoginRequest, LoginResponse } from 'api/models';

export class AuthenticationService {
  /**
   * Log a user in, returning a token
   * @param params Login info
   */
  public static login = (params: LoginRequest) => {
    return http.client.post<LoginResponse>(`/auth/login`, params);
  };

  /**
   * Log a user out, invalidating their token
   */
  public static logout = () => {
    return http.client.post(`/auth/logout`);
  };
}
