export const getToken = (): string | undefined => {
  try {
    return localStorage.getItem('sage-token') ?? undefined;
  } catch {
    return undefined;
  }
};

export const setToken = (token: string) => {
  window.localStorage.setItem('sage-token', token);
};
export const clearToken = () => {
  window.localStorage.removeItem('sage-token');
};
