import React from 'react';
import { connect, useSelector } from 'react-redux';
import {
  Collapse,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import {
  HomeRounded,
  ExpandLessRounded,
  ExpandMoreRounded,
  ReceiptRounded,
  FindInPageRounded,
} from '@material-ui/icons';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { signOut } from 'state/actions/auth';
import { TopNav } from 'components/Navigation';
import { Logo } from 'components/Logo';
import { AuthenticationService } from 'api/services';
import { isMobileSelector } from 'state/actions/ui';

const drawerWidth = 180;

const Container = styled.div`
  display: flex;
  main {
    display: block;
    width: 100%;
  }
`;

const SideNav = styled(Drawer)`
  width: ${drawerWidth}px;
  .paper {
    width: ${drawerWidth}px;
  }
  .MuiListItemIcon-root {
    min-width: 40px;
  }
  .footer {
    align-self: flex-end;
  }
`;

const HeaderLogo = styled(Logo)`
  padding: 6px 8px 0 8px;
  fill: #d51539;
  width: 100%;
`;

const PadContainer = styled(List)`
  padding: 0;
  .MuiButtonBase-root {
    padding-left: 2em;
  }
`;

interface NavItemProps {
  primary: string;
  to: string;
  icon: JSX.Element;
}

const NavItem = (props: NavItemProps) => (
  <ListItem button component={Link} to={props.to}>
    <ListItemIcon>{props.icon}</ListItemIcon>
    <ListItemText primary={props.primary} />
  </ListItem>
);

const NavHeader = () => (
  <ListItem button component={Link} to="/">
    <HeaderLogo />
  </ListItem>
);

interface NestedContainerProps {
  title: string;
  icon: JSX.Element;
  children: React.ReactNode[] | React.ReactNode;
}

const NestedContainer = (props: NestedContainerProps) => {
  const [open, setOpen] = React.useState(false);
  const toggleOpen = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setOpen(!open);
    event.stopPropagation();
  };

  return (
    <React.Fragment>
      <ListItem button onClick={toggleOpen}>
        <ListItemIcon>{props.icon}</ListItemIcon>
        <ListItemText primary={props.title} />
        {open ? <ExpandLessRounded /> : <ExpandMoreRounded />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding={true}>
          <PadContainer>{props.children}</PadContainer>
        </List>
      </Collapse>
    </React.Fragment>
  );
};

interface NavigationProps {
  signOut: typeof signOut;
  children: React.ReactNode;
}

const NavigationComponent = (props: NavigationProps) => {
  const intl = useIntl();
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const isMobile = useSelector(isMobileSelector);

  const handleSignOut = () => {
    AuthenticationService.logout().then(() => {
      props.signOut();
    });
  };

  const openDrawer = () => setDrawerOpen(true);

  const closeDrawer = () => {
    if (!isMobile || !drawerOpen) {
      return;
    }
    setDrawerOpen(false);
  };

  return (
    <Container onClick={closeDrawer}>
      <TopNav menuCallback={openDrawer} signOutCallback={handleSignOut} />
      <nav>
        <SideNav
          variant={isMobile ? 'temporary' : 'permanent'}
          open={drawerOpen}
          onClose={closeDrawer}
          classes={{ paper: 'paper' }}
        >
          <List>
            <NavHeader />
            <Divider />
            <NavItem
              to="/"
              icon={<HomeRounded />}
              primary={intl.formatMessage({
                id: 'nav.home',
                defaultMessage: 'Home',
              })}
            />

            <NestedContainer
              title={intl.formatMessage({
                id: 'nav.invoices',
                defaultMessage: 'Invoices',
              })}
              icon={<ReceiptRounded />}
            >
              <NavItem
                to="/invoices"
                icon={<FindInPageRounded />}
                primary={intl.formatMessage({
                  id: 'nav.overview',
                  defaultMessage: 'Overview',
                })}
              />
            </NestedContainer>
          </List>
        </SideNav>
      </nav>
      <main>{props.children}</main>
    </Container>
  );
};

export const Navigation = connect(null, { signOut: signOut })(
  NavigationComponent
);
