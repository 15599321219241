import React from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { useMediaQuery } from '@material-ui/core';

import { Router } from 'components/Router';
import { Snackbar } from 'components/common';
import { UserService } from 'api/services';
import { getCancelToken } from 'api/core/http';
import { setDesktopView, setMobileView } from 'state/actions/ui';
import { theme } from 'util/theme';
import { userInfo } from 'state/actions/user';

const Container = styled.div`
  padding: 16px;
`;

export const App = () => {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  React.useEffect(() => {
    isMobile ? dispatch(setMobileView()) : dispatch(setDesktopView());
  }, [dispatch, isMobile]);

  React.useEffect(() => {
    UserService.whoami().then(user => {
      dispatch(userInfo(user.data));
    });

    const cancellation = getCancelToken();
    return () => {
      cancellation.cancel('Requests cancelled.');
    };
  }, [dispatch, isMobile]);

  return (
    <React.Fragment>
      <Container>
        <Router />
      </Container>
      <Snackbar />
    </React.Fragment>
  );
};
