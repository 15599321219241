import { createStore, combineReducers, compose } from 'redux';
import * as Sentry from '@sentry/react';
import { authReducer } from 'state/reducers/auth';
import { userReducer } from 'state/reducers/user';
import { snackbarReducer } from 'state/reducers/snackbar';
import { uiReducer } from 'state/reducers/ui';

export type AppState = ReturnType<typeof rootReducer>;

const rootReducer = combineReducers({
  auth: authReducer,
  user: userReducer,
  snackbar: snackbarReducer,
  ui: uiReducer,
});

const sentryRedux = Sentry.createReduxEnhancer();
const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const configureStore = () => {
  const store = createStore(
    rootReducer,
    composeEnhancers(
      sentryRedux
    )
  );
  return store;
};

export const store = configureStore();
