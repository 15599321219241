import axios from 'axios';
import { store } from 'state/store';
import { signOut } from 'state/actions/auth';
import { getToken, clearToken } from 'api/core/token';

export class http {
  public static client = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });
}

http.client.interceptors.request.use(config => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

http.client.interceptors.response.use(
  response => {
    return response;
  },
  function (error) {
    if (error.response.status === 401) {
      store.dispatch(signOut());
      clearToken();
      return;
    }
    return Promise.reject(error);
  }
);

export const getCancelToken = () => {
  return axios.CancelToken.source();
};
