import {
  AuthState,
  AuthActionTypes,
  SIGN_IN,
  SIGN_OUT,
} from 'state/types/auth';
import { getToken } from 'api/core/token';

const initialState: AuthState = {
  accessToken: getToken(),
  signedIn: getToken() !== undefined,
};

export const authReducer = (
  state = initialState,
  action: AuthActionTypes
): AuthState => {
  switch (action.type) {
    case SIGN_IN: {
      return {
        ...state,
        accessToken: action.payload.token,
        signedIn: true,
      };
    }
    case SIGN_OUT: {
      return {
        signedIn: false,
      };
    }
    default:
      return state;
  }
};
