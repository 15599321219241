import React from 'react';
import debounce from 'lodash/debounce';
import { PaginatedTable } from 'components/Table';
import { Data, ColumnMap } from 'components/Table/types';
import { TransactionService } from 'api/services';
import { RequestOptions } from 'api/core/RequestOptions';
import { Container } from 'components/common/Container';
import { useIntl } from 'react-intl';

interface TransactionTableProps {
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
}

export const TransactionTable = ({ page, setPage }: TransactionTableProps) => {
  const [data, setData] = React.useState<Data>([]);
  const [count, setCount] = React.useState(0);
  const [perPage, setPerPage] = React.useState(25);
  const [loading, setLoading] = React.useState(true);

  const intl = useIntl();

  const debouncedGetTransactions = React.useCallback(
    debounce((options: RequestOptions) => {
      TransactionService.getTransactions(options)
        .then(res => {
          setCount(() => res.data.meta.total);
          setData(() => res.data.data);
        })
        .finally(() => {
          setLoading(false);
        });
    }, 300),
    []
  );

  React.useEffect(() => {
    setLoading(() => true);
    debouncedGetTransactions({ page: page + 1, per_page: perPage });
  }, [page, perPage, debouncedGetTransactions]);

  const paginate = (e: unknown, page: number) => {
    setPage(page);
  };

  const columns: ColumnMap = {
    date: {
      name: intl.formatMessage({
        id: 'table.date',
        defaultMessage: 'Date',
      }),
      type: 'datetime',
    },
    customer_name: {
      name: intl.formatMessage({
        id: 'table.customer',
        defaultMessage: 'Customer',
      }),
    },
    product_name: {
      name: intl.formatMessage({
        id: 'table.product',
        defaultMessage: 'Product',
      }),
      hideOnMobile: true,
    },
    location_name: {
      name: 'Location',
    },
    amount: {
      name: intl.formatMessage({
        id: 'table.amount',
        defaultMessage: 'Amount',
      }),
      type: 'integer',
      align: 'center',
    },
    volume: {
      name: intl.formatMessage({
        id: 'table.volume',
        defaultMessage: 'Volume',
      }),
      type: 'decimal',
      hideOnMobile: true,
    },
    card: {
      name: intl.formatMessage({
        id: 'table.card',
        defaultMessage: 'Card',
      }),
      hideOnMobile: true,
    },
  };

  return (
    <Container>
      <PaginatedTable
        columns={columns}
        data={data}
        page={page}
        rowsPerPage={perPage}
        onPaginate={paginate}
        onPerPage={event => setPerPage(() => parseInt(event.target.value))}
        count={count}
        loading={loading}
      />
    </Container>
  );
};
