import {
  CircularProgress,
  CircularProgressProps,
  Box,
} from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';

interface ProgressProps extends CircularProgressProps {
  indicatorColor: string;
  pad?: boolean;
}
const Progress = ({
  indicatorColor,
  pad,
  className,
  ...rest
}: ProgressProps) => (
  <Box className={`${className} wrapper`}>
    <CircularProgress {...rest} />
  </Box>
);

export const Loading = styled(Progress)`
  &.wrapper {
    padding: ${props => (props.pad ? '12px' : '0px')};
    display: flex;
    align-items: center;
    justify-items: center;
  }
  circle {
    color: ${props => props.indicatorColor};
  }
`;
